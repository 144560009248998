import React from "react"
import "./RequestForm.scss"
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone"
import axios from "axios"
import { Component } from "react"
import { nanoid } from "nanoid"
import { toast } from "react-hot-toast"
import Toast from "../Toast/Toast"

class RequestForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ad: "",
            sanatci: "",
            sarki: "",
            mesaj: "",
            ipadres: "",
        }
    }
    componentDidMount() {
        const getData = async () => {
            try {
                const res = await axios.get("https://geolocation-db.com/json/")
                res.data.IPv4 && this.setState({ ipadres: res.data.IPv4 })
                if (res.data.IPv4) {
                    this.setState({ ipadres: res.data.IPv4 })
                }
            } catch (err) {
                if (localStorage.getItem("nanoid")) {
                    this.setState({ ipadres: localStorage.getItem("nanoid") })
                } else {
                    localStorage.setItem("nanoid", nanoid())
                    this.setState({ ipadres: nanoid() })
                }
            }
        }
        getData()
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    closeRequestForm = () => {
        document.querySelector(".request-form-con-cover").style.display = "none"
        document.querySelector("body").style.overflowY = "scroll"
        this.setState({ ad: "" })
        this.setState({ sanatci: "" })
        this.setState({ sarki: "" })
        this.setState({ mesaj: "" })
        var deg = document.querySelectorAll(".get-input")
        deg.forEach((element) => {
            element.value = ""
        })
    }

    submitHandler = (e) => {
        e.preventDefault()

        fetch(`https://vekilhac.com/api/istek`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                ad: this.state.ad,
                sanatci: this.state.sanatci,
                sarki: this.state.sarki,
                mesaj: this.state.mesaj,
                ipadres: this.state.ipadres,
            }),
        })
            .then((res) => res.json())
            .then((dt) => {
                if (dt.status === "success") {
                    this.closeRequestForm()
                    toast.custom(<Toast message="İstek şarkınız gönderildi" variant="success" />)
                } else {
                    this.closeRequestForm()
                    toast.custom(<Toast message="İstek gönderilemedi" variant="error" />)
                }
            })
    }

    render() {
        return (
            <div className="request-form-con-cover">
                <div className="request-form-con">
                    <div className="request-form">
                        <div className="request-form-header">İstek Gönder</div>
                        <div className="request-exit-button">
                            <CloseTwoToneIcon className="request-exit-icon" onClick={this.closeRequestForm} />
                        </div>
                        <form onSubmit={this.submitHandler}>
                            <div className="input-nick">
                                <div className="input-label">Nick - Ad</div>
                                <input
                                    className="get-input"
                                    name="ad"
                                    onChange={this.changeHandler}
                                    value={this.state.ad}
                                    type={"text"}
                                    required
                                />
                            </div>

                            <div className="input-artist-music">
                                <div className="input-artist">
                                    <div className="input-label">Sanatçı</div>
                                    <input
                                        className="get-input"
                                        name="sanatci"
                                        onChange={this.changeHandler}
                                        value={this.state.sanatci}
                                        type={"text"}
                                        required
                                    />
                                </div>

                                <div className="input-music">
                                    <div className="input-label">Şarkı</div>
                                    <input
                                        className="get-input"
                                        name="sarki"
                                        onChange={this.changeHandler}
                                        value={this.state.sarki}
                                        type={"text"}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="input-message">
                                <div className="input-label">Mesaj</div>
                                <input
                                    className="get-input"
                                    name="mesaj"
                                    onChange={this.changeHandler}
                                    value={this.state.mesaj}
                                    type={"text"}
                                    required
                                />
                            </div>

                            <div className="input-submit">
                                <input type={"submit"} className="request-submit-button" value={"Gönder"} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default RequestForm
