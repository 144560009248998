import PlayCircleIcon from "@mui/icons-material/PlayCircle"
import PauseCircleIcon from "@mui/icons-material/PauseCircle"
import styles from "../LiveRadio.module.scss"

export default function PlayBtn({ volume, playPause, setPlayPause, setVolumeIcon, setValue }) {
    return (
        <button className={styles.playBtn}>
            {playPause || volume === "0" ? (
                <PlayCircleIcon
                    onClick={() => {
                        setPlayPause(false)
                        setVolumeIcon(true)
                        setValue((localStorage.getItem("volume") === "0" ? 10 : localStorage.getItem("volume")) || 10)
                    }}
                />
            ) : (
                <PauseCircleIcon
                    onClick={() => {
                        setPlayPause(true)
                        setVolumeIcon(false)
                        setValue(0)
                    }}
                />
            )}
        </button>
    )
}
