import styles from "../LiveRadio.module.scss"

export default function Title({ musicInfo }) {
    return (
        <div className={styles.title}>
            {musicInfo.title.src.length >= 25 ? (
                <marquee>{musicInfo.title.src ? musicInfo.title.src : musicInfo.title.none}</marquee> // eslint-disable-line
            ) : (
                <div>{musicInfo.title.src ? musicInfo.title.src : musicInfo.title.none}</div>
            )}
            <span>{musicInfo.subTitle.src ? musicInfo.subTitle.src : musicInfo.subTitle.none}</span>
        </div>
    )
}
