import React from "react"
import Last5List from "./Last5List/Last5List"
import TrackList from "./TrackList/TrackList"
import DiscordArea from "./DiscordArea/DiscordArea"
import styles from "./GeneralRadioArea.module.scss"

export default function GeneralRadioArea({ trackListApi }) {
    return (
        <div className={styles.generalRadioArea} id="general-body">
            <Last5List />
            <TrackList trackListApi={trackListApi} />
            <DiscordArea />
        </div>
    )
}
