import React, { useEffect, useState } from "react"
import discordLogo from "../../../assets/images/DiscordLogo.png"
import discordLogoDark from "../../../assets/images/DiscordLogoDark.png"
import SendIcon from "@mui/icons-material/Send"
import styles from "../GeneralRadioArea.module.scss"
import { useSelector } from "react-redux"

export default function DiscordArea() {
    const { darkMode } = useSelector((state) => state.site)
    const [messages, setMessages] = useState([])

    const discordInviteFade = function () {
        document.querySelector("#discordSendMessageArea").style.display = "none"
        document.querySelector("#discordInviteButton").style.display = "block"

        setTimeout(() => {
            document.querySelector("#discordSendMessageArea").style.display = "block"
            document.querySelector("#discordInviteButton").style.display = "none"
        }, 10000)
    }

    useEffect(() => {
        fetch("https://discord.com/api/guilds/707350118831226951/widget.json")
            .then((response) => response.json())
            .then((data) => {
                document.querySelector("#discordAreaMember").innerHTML = data.presence_count + " Aktif Üye"
            })
    }, [])

    useEffect(() => {
        const discordArea = document.querySelector("#discordArea")
        let isScroll = true

        setInterval(() => {
            fetch("https://vekilhac.com/api/radyochat")
                .then((response) => response.json())
                .then((data) => {
                    setMessages([...data])

                    setTimeout(() => {
                        if (isScroll) {
                            discordArea.scrollTop = discordArea.scrollHeight
                            isScroll = false
                        }
                    }, 500)
                })
                .catch((err) => console.log(err))
        }, 3000)
    }, [])

    return (
        <div className={styles.discord}>
            <div className={styles.discordAreaHeader}>
                <img src={darkMode ? discordLogoDark : discordLogo} alt="görsel yüklenemedi" />
            </div>
            <div className={styles.discordAreaInfo}>
                <div className={styles.discordAreaChannel}>#📻・radyo-chat</div>
                <div id="discordAreaMember" className={styles.discordAreaMember}>
                    10.000 üye
                </div>
            </div>
            <ul id="discordArea" className={styles.discordMessageArea}>
                {messages.map((msg, index) => (
                    <li key={index} className={styles.messagesCtnr}>
                        <span style={{ color: msg.color }} className={styles.username}>
                            {msg.author}:
                        </span>
                        <p>{msg.message}</p>
                    </li>
                ))}
            </ul>
            <div className={styles.discordInviteArea}>
                <div id="discordSendMessageArea" className={styles.discordSendMessageArea}>
                    <div className={styles.discordSendMessage} onClick={discordInviteFade}>
                        <div className={styles.discordSendMessageText}>Mesaj gönder</div>
                        <SendIcon fontSize="small" />
                    </div>
                </div>
                <div id="discordInviteButton" className={styles.discordInviteButton}>
                    <a href="https://discord.gg/yazilimcimekani" target="_blank" rel="noopener noreferrer">
                        <div>Sunucuya Katıl</div>
                    </a>
                </div>
            </div>
        </div>
    )
}
