import React from "react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import RangeInput from "./elements/RangeInput"
import VolumeBtn from "./elements/VolumeBtn"
import PlayBtn from "./elements/PlayBtn"
import Progress from "./elements/Progress"
import Title from "./elements/Title"
import styles from "./LiveRadio.module.scss"
import liveDefaultImg from "../../assets/images/live_image.png"

function LiveRadio({ dataRequest }) {
    const { data } = useSelector((state) => state.datas)
    const [duration, setDuration] = useState("")
    const [progressTime, setProgressTime] = useState("")
    const [volume, setValue] = useState(localStorage.getItem("volume") || 10)
    const [playPause, setPlayPause] = useState(true)
    const [volumeIcon, setVolumeIcon] = useState(true)
    const [lastTime, setLastTime] = useState(0)
    const [time, setTime] = useState(0)
    const { now_playing } = data
    let last = 0
    let playedAt = 0

    const [musicInfo, setMusicInfo] = useState({
        img: {
            src: "",
            none: "",
        },
        title: {
            src: "",
            none: "",
        },
        subTitle: {
            src: "",
            none: "",
        },
        duration: {
            time: "",
            none: "00:00",
        },
    })

    const changeAudioVolume = (volume) => {
        document.querySelector("#radyo").volume = volume / 100
    }

    function formatTime(s) {
        return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s
    }

    const loadData = () => {
        let minustes = data.now_playing.duration
        setDuration((minustes - (minustes %= 60)) / 60 + (9 < minustes ? ":" : ":0") + minustes)
        setLastTime(Date.now() - now_playing.played_at)
        playedAt = now_playing.played_at
        last = 0
        setTime(0)
        last = now_playing.elapsed
        let test = 0
        test = lastTime
        setLastTime((lastTime - (test %= 60)) / 60 + (9 < lastTime ? ":" : ":0") + lastTime)
        setMusicInfo({
            ...musicInfo,
            img: now_playing.song.art ? { src: now_playing.song.art, none: "" } : { src: "", none: "Resim bulunamadı" },
            title: now_playing.song.title ? { src: now_playing.song.title, none: "" } : { src: now_playing.song.album, none: "" },
            subTitle: now_playing.song.artist
                ? { src: now_playing.song.artist, none: "" }
                : { src: "", none: "Sanatçı bulunamadı" },
            duration: lastTime ? { time: lastTime, none: "" } : { none: "00:00", time: "" },
        })
        volume === "0" && setVolumeIcon(false)
    }

    useEffect(() => {
        loadData()
        const interval = setInterval(() => {
            setLastTime((prev) => prev - 1)
            let now = Math.round(new Date().getTime() / 1000)
            let sure = now - playedAt
            setProgressTime(formatTime(sure))
            last += 1
            setTime(last)
            if (last === data.now_playing.duration + 1) {
                dataRequest()
                loadData()
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [data])

    const radyoElement = document.querySelector("#radyo")
    if (radyoElement) {
        // Radyo audio element
        changeAudioVolume(volume)
        radyoElement
            .play()
            .then()
            .catch((e) => {})
        radyoElement.addEventListener("play", (e) => {
            setPlayPause(false)
        })
        radyoElement.addEventListener("pause", (e) => {
            setPlayPause(true)
        })

        // progress bar
        const progress = document.querySelector("#progress")

        if (!data.live.is_live) {
            progress.addEventListener("mousemove", (e) => {
                const progressTime = document.querySelector(".progressTime")
                progressTime.style.left = `${e.offsetX}px`
            })
        }
    }

    return (
        <div className={styles.liveRadioContainer}>
            <div className={styles.liveRadioInner}>
                <div className={styles.imgArea}>
                    {musicInfo.img.src && !data.live.is_live ? (
                        <img src={musicInfo.img.src} alt="" />
                    ) : (
                        data.live.is_live && <img src={liveDefaultImg} alt="" />
                    )}
                </div>
                <div className={styles.nowPlaying}>
                    <Title musicInfo={musicInfo} />
                    <Progress duration={duration} time={time} now_playing={now_playing} formatTime={formatTime} />
                    <div className={styles.btnCtnr}>
                        <audio autoPlay id="radyo" src={data.station.listen_url} />
                        <PlayBtn
                            setVolumeIcon={setVolumeIcon}
                            setPlayPause={setPlayPause}
                            setValue={setValue}
                            volumeIcon={volumeIcon}
                            volume={volume}
                            playPause={playPause}
                        />
                    </div>
                    <div className={styles.volumeCtnr}>
                        <VolumeBtn
                            setVolumeIcon={setVolumeIcon}
                            setPlayPause={setPlayPause}
                            setValue={setValue}
                            volumeIcon={volumeIcon}
                            changeAudioVolume={changeAudioVolume}
                            volume={volume}
                        />
                        <RangeInput
                            volume={volume}
                            setValue={setValue}
                            changeAudioVolume={changeAudioVolume}
                            setVolumeIcon={setVolumeIcon}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveRadio
