import { configureStore } from "@reduxjs/toolkit"
import dataSlice from "./dataSlice"
import siteSlice from "./siteSlice"

export const store = configureStore({
    reducer: {
        datas: dataSlice,
        site: siteSlice,
    },
})
