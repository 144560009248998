import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    data: {},
    status: null,
}

export const dataSlice = createSlice({
    name: "dataSlice",
    initialState,
    reducers: {
        loadData: (state, action) => {
            state.data = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
    },
})

export const { loadData, setStatus } = dataSlice.actions
export default dataSlice.reducer
