import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    darkMode: localStorage.getItem("darkMode") ? JSON.parse(localStorage.getItem("darkMode")) : true,
}

export const siteSlice = createSlice({
    name: "siteSlice",
    initialState,
    reducers: {
        switchTheme: (state, action) => {
            state.darkMode = action.payload
            localStorage.setItem("darkMode", state.darkMode)
            state.darkMode ? document.body.classList.add("dark") : document.body.classList.remove("dark")
        },
    },
})

export const { switchTheme } = siteSlice.actions
export default siteSlice.reducer
