import React from "react"
import LiveRadio from "../LiveRadio/LiveRadio"
import logo from "../../assets/images/yazilimcimekani.png"
import logodark from "../../assets/images/yazilimcimekanidark.png"
import styles from "./LiveRadioArea.module.scss"
import { useSelector } from "react-redux"

export default function LiveRadioArea({ dataRequest, stoped }) {
    const { darkMode } = useSelector((state) => state.site)

    return (
        <div className={styles.liveRadioArea}>
            <div className={styles.categoryButtons}>
                <button disabled={stoped} onClick={() => dataRequest(1)}>
                    <img src={darkMode ? logodark : logo} alt="404" />
                    <span style={{ fontSize: 22 }}>Radyo</span>
                </button>
                <button disabled={stoped} onClick={() => dataRequest(3)}>
                    <img src={darkMode ? logodark : logo} alt="404" />
                    <span>Slow</span>
                </button>
                <button disabled={stoped} onClick={() => dataRequest(4)}>
                    <img src={darkMode ? logodark : logo} alt="404" />
                    <span>Pop</span>
                </button>
                <button disabled={stoped} onClick={() => dataRequest(5)}>
                    <img src={darkMode ? logodark : logo} alt="404" />
                    <span style={{ fontSize: 20 }}>Nostalji</span>
                </button>
            </div>
            <LiveRadio className={styles.nowPlay} dataRequest={dataRequest} />
            <div className={styles.scrollCtnr}>
                <a href="#general-body">
                    <div className={styles.scrollDown}></div>
                </a>
            </div>
        </div>
    )
}
