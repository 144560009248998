import { BiErrorCircle } from "react-icons/bi"
import { IoIosCheckmarkCircle } from "react-icons/io"
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import styles from "./Toast.module.scss"

export default function Toast({ message, variant }) {
    return (
        <div className={styles.Toast}>
            {variant === "success" && <IoIosCheckmarkCircle className={styles.success} />}
            {variant === "error" && <BiErrorCircle className={styles.error} />}
            {variant === "promise" && <AiOutlineLoading3Quarters className={styles.promise} />}
            <span>{message}</span>
        </div>
    )
}

// t.visible
