import { useSelector } from "react-redux"
import styles from "../LiveRadio.module.scss"

export default function Progress({ duration, time, now_playing, formatTime }) {
    const { data } = useSelector((state) => state.datas)
    return (
        <div className={styles.progress}>
            <progress id="progress" value={time} max={now_playing.duration} />
            {data.live.is_live ? (
                <div className={styles.liveTextCtnr}>
                    <div></div>
                    <span>Canlı</span>
                </div>
            ) : (
                <span>{duration}</span>
            )}
            <span className={`${styles.progressTime} progressTime`}>{formatTime(time)}</span>
        </div>
    )
}
