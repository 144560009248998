import React, { useEffect } from "react"
import "./datatable.css"
import styles from "../GeneralRadioArea.module.scss"
import { useSelector } from "react-redux"
import Toast from "../../Toast/Toast"
import { toast } from "react-hot-toast"

const $ = require("jquery")
$.DataTable = require("datatables.net")

export default function TrackList({ trackListApi }) {
    const { data } = useSelector((state) => state.datas)

    useEffect(() => {
        fetch(trackListApi)
            .then((response) => response.json())
            .then((json) => {
                if (!data.live.is_live) {
                    document.querySelector("#tableContainer").innerHTML = `
                        <table id="sarkiistek" className="display">
                            <tbody></tbody>
                        </table>
                    `
                    var table = $("#sarkiistek").DataTable({
                        data: json,
                        columns: [{ data: "text" }, { data: "istekbuton" }],
                        pageLength: 6,
                        ordering: false,
                        language: {
                            emptyTable: "Gösterilecek veri yok.",
                            processing: "Veriler yükleniyor",
                            sDecimal: ".",
                            sInfo: "_TOTAL_ kayıttan _START_ - _END_ arası kayıtlar gösteriliyor",
                            sInfoFiltered: "(_MAX_ kayıt içerisinden bulunan)",
                            sInfoPostFix: "",
                            sInfoThousands: ".",
                            sLengthMenu: "Sayfada _MENU_ kayıt göster",
                            sLoadingRecords: "Yükleniyor...",
                            sSearch: "",
                            searchPlaceholder: "Şarkı ara...",
                            sZeroRecords: "Eşleşen kayıt bulunamadı",
                            oPaginate: {
                                sFirst: "İlk",
                                sLast: "Son",
                                sNext: `>`,
                                sPrevious: "<",
                            },
                            oAria: {
                                sSortAscending: ": artan sütun sıralamasını aktifleştir",
                                sSortDescending: ": azalan sütun sıralamasını aktifleştir",
                            },
                            select: {
                                rows: {
                                    _: "%d kayıt seçildi",
                                    0: "",
                                    1: "1 kayıt seçildi",
                                },
                            },
                        },
                    })
                    table.on("click", ".track-request", function (e) {
                        Toast("İstek gönderildi")
                        fetch(`https://radio.yazilimcimekani.com/api/station/1/request/${e.target.value}`, {
                            method: "POST",
                        })
                            .then((response) => response.json())
                            .then((json) => {
                                if (json.success === true) {
                                    toast.custom(<Toast message="İstek şarkınız gönderildi" variant="success" />)
                                } else if (json.success === false) {
                                    toast.custom(<Toast message="Daha önce bir istekte bulundunuz" variant="error" />)
                                }
                            })
                    })
                } else {
                    document.querySelector("#tableContainer").innerHTML = `
                        <div style="display:flex;height:100%;justify-content:center;align-items:center;">
                            <h2 style="text-align:center;">Radyo şuan yayında</h2>
                        </div>
                    `
                }
            })
    }, [trackListApi, data.live.is_live])

    return <div id="tableContainer" className={styles.trackListCon}></div>
}
