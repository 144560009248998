export default function RangeInput({ changeAudioVolume, setVolumeIcon, setValue, volume }) {
    const changeHandle = (e) => {
        localStorage.setItem("volume", e.target.value)
        setValue(e.target.value)
        e.target.value === "0" ? setVolumeIcon(false) : setVolumeIcon(true)
        changeAudioVolume(Number(e.target.value))
    }

    return <input onInput={changeHandle} type="range" value={volume} min="0" max="50" />
}
