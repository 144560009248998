import React from "react"
import styles from "./Navbar.module.scss"
import InstagramIcon from "@mui/icons-material/Instagram"
import TwitterIcon from "@mui/icons-material/Twitter"
import GithubIcon from "@mui/icons-material/GitHub"
import logo from "../../assets/images/yazilimcimekani.png"
import logodark from "../../assets/images/yazilimcimekanidark.png"
import LightModeIcon from "@mui/icons-material/LightMode"
import DarkModeIcon from "@mui/icons-material/DarkMode"
import MusicNoteIcon from "@mui/icons-material/MusicNote"
import { useDispatch, useSelector } from "react-redux"
import { switchTheme } from "../../store/siteSlice"

export default function Navbar() {
    const { darkMode } = useSelector((state) => state.site)
    const dispatch = useDispatch()

    return (
        <nav className={styles.navbar}>
            <div className={styles.logo}>
                <a href="https://discord.gg/yazilimcimekani" target="_blank" rel="noopener noreferrer">
                    <img src={darkMode ? logodark : logo} alt="görsel yüklenemedi" />
                    <MusicNoteIcon />
                </a>
            </div>
            <div className={styles.marquee}>
                {
                    // eslint-disable-next-line
                    <marquee loop className="noticebar-marquee">
                        Radyo Yazılımcı Mekanı Yazılımcının Sesi! Radyo Programlarımızdan haberdar olmak için Discord sunucumuza
                        katılmayı unutmayın! İstekte bulunurken lütfen not bırakmayı unutmayınız!
                    </marquee>
                }
            </div>
            <div className={styles.socialMedia}>
                <a href="https://www.instagram.com/yazilimci_mekani/" target="_blank" rel="noreferrer">
                    <InstagramIcon />
                </a>

                <a href="https://twitter.com/Radyo_YM" target="_blank" rel="noreferrer">
                    <TwitterIcon />
                </a>

                <a href="https://github.com/yazilimcimekani" target="_blank" rel="noreferrer">
                    <GithubIcon />
                </a>
                <button onClick={() => dispatch(switchTheme(!darkMode))} className="theme-btn">
                    {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
                </button>
            </div>
        </nav>
    )
}
