import React from "react"
import { useSelector } from "react-redux"
import styles from "../GeneralRadioArea.module.scss"

export default function Last5List() {
    const { data } = useSelector((state) => state.datas)

    let history = data.song_history

    const formatDate = (date) => {
        return Math.floor((Math.floor(Date.now() / 1000) - date) / 60) + " dk"
    }

    return (
        <div className={styles.last5list}>
            <div className={styles.last5listHeader}>Çalan son 5 şarkı</div>
            <ul className={styles.historyList}>
                {history.map((dt, index) => (
                    <li className={styles.historyItem} key={index}>
                        <span className={styles.historyNumber}>{index + 1}</span>
                        <div className={styles.historyArt}>
                            <img src={dt.song.art} alt="" />
                        </div>
                        <div className={styles.historyArtistTitle}>
                            <span className={styles.historyTitle}>{dt.song.title}</span>
                            <span className={styles.historyArtist}>{dt.song.artist}</span>
                        </div>
                        <span className={styles.historyPlayedAt}>{formatDate(dt.played_at)}</span>
                    </li>
                ))}
            </ul>
        </div>
    )
}
