import VolumeUpIcon from "@mui/icons-material/VolumeUp"
import VolumeOff from "@mui/icons-material/VolumeOff"
import styles from "../LiveRadio.module.scss"

export default function VolumeBtn({ setVolumeIcon, setPlayPause, setValue, volumeIcon, changeAudioVolume, volume }) {
    return (
        <div className={styles.rangeValue}>
            {volumeIcon ? (
                <VolumeUpIcon
                    onClick={() => {
                        setVolumeIcon(!volumeIcon)
                        setPlayPause(true)
                        setValue(0)
                        changeAudioVolume(0)
                    }}
                    className={styles.volumeUp}
                />
            ) : (
                <VolumeOff
                    onClick={() => {
                        setVolumeIcon(!volumeIcon)
                        setPlayPause(false)
                        setValue((localStorage.getItem("volume") === "0" ? 10 : localStorage.getItem("volume")) || 20)
                        changeAudioVolume(Number(localStorage.getItem("volume")) || 10)
                    }}
                    className={styles.volumeOff}
                />
            )}

            <span>%{volume * 2}</span>
        </div>
    )
}
