import React, { useState } from "react"
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna"
import GeneralRadioArea from "./components/GeneralRadioArea/GeneralRadioArea"
import LiveRadioArea from "./components/LiveRadioArea/LiveRadioArea"
import Navbar from "./components/Navbar/Navbar"
import RequestForm from "./components/RequestForm/RequestForm"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import axios from "axios"
import { loadData, setStatus } from "./store/dataSlice"
import { Player } from "@lottiefiles/react-lottie-player"
import { Toaster } from "react-hot-toast"

export default function App() {
    const dispatch = useDispatch()
    const { status, data } = useSelector((state) => state.datas)
    const { darkMode } = useSelector((state) => state.site)
    const [stoped, setStoped] = useState(false)
    const [trackListApi, setTrackListApi] = useState("https://vekilhac.com/api/genel/sarkilar")
    const [categoryId, setCategoryId] = useState(1)

    const requestFormOpen = function () {
        document.querySelector(".request-form-con-cover").style.display = "block"
        document.querySelector(".request-form-con").style.height = window.height + "px"
        document.querySelector("body").style.overflowY = "hidden"
    }

    const dataRequest = async (id) => {
        id === undefined && (id = categoryId)
        setCategoryId(id)
        try {
            const response = await axios.get(`https://radio.yazilimcimekani.com/api/nowplaying/${id}`)
            dispatch(loadData(response.data))
            dispatch(setStatus(true))

            let title = "Radyo"

            if (id === 3) {
                title = "Slow"
                setTrackListApi("https://vekilhac.com/api/slow/sarkilar")
            } else if (id === 4) {
                title = "Pop"
                setTrackListApi("https://vekilhac.com/api/pop/sarkilar")
            } else if (id === 5) {
                title = "Nostalji"
                setTrackListApi("https://vekilhac.com/api/nostalji/sarkilar")
            } else {
                title = "Radyo"
                setTrackListApi("https://vekilhac.com/api/genel/sarkilar")
            }

            document.title = `Yazılımcı Mekanı ${title}`
        } catch (error) {
            console.log(error)
            dispatch(setStatus(false))
        }
        setTimeout(() => {
            setStoped(false)
        }, 1000)
    }

    useEffect(() => {
        dataRequest(categoryId)
    }, [])

    useEffect(() => {
        darkMode ? document.body.classList.add("dark") : document.body.classList.remove("dark")
    }, [darkMode])

    if (status) {
        return (
            <div className="App dark-theme-on">
                <div className="confirm-box"></div>
                <Navbar />
                <LiveRadioArea stoped={stoped} dataRequest={dataRequest} />
                <GeneralRadioArea trackListApi={trackListApi} />
                {data.live.is_live && (
                    <div className="request-form-button" onClick={requestFormOpen}>
                        <SettingsInputAntennaIcon />
                        <span>İstek Yap</span>
                    </div>
                )}
                <RequestForm />
                <Toaster position="top-right" reverseOrder={false} />
            </div>
        )
    } else if (status === false) {
        return (
            <div className="loading-screen">
                <h2>Bir şeyler ters gitti !</h2>
                <span>Lütfen bir kaç dakika sonra sayfayı yenileyin</span>
            </div>
        )
    } else {
        return (
            <div className="loading-screen">
                <Player
                    autoplay
                    loop
                    src="https://lottie.host/5dff96ba-e008-40af-b00e-adf9cf2fd91c/PcJnlGGWOw.json"
                    style={{ height: "150px", width: "150px" }}
                ></Player>
            </div>
        )
    }
}
